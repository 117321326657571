import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';
import tw, { css } from 'twin.macro';

import { rgbToCssColor, stitchesToDimensions } from '@chartsy/shared/utils';

import { Scalars, ThemeImageThemePublicFragment } from '../generatedGraphql';

interface ThemeImageProps {
  chartDimensions: Scalars['dimensions'];
  className?: string;
  dimBackground?: boolean;
  name: string;
  priority?: boolean;
  themePublic: Omit<ThemeImageThemePublicFragment, '__typename'>;
}

const foregroundShadow = css`
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2))
    drop-shadow(0 2px 4px rgba(0, 0, 0, 0.4));
`;

const foregroundImageWrapper = css`
  > div {
    ${tw`margin[8%]! overflow-visible!`}
  }
  img {
    ${foregroundShadow}
  }
`;

const ThemeImage = ({
  chartDimensions,
  className,
  dimBackground = false,
  name,
  priority = false,
  themePublic,
}: ThemeImageProps) => {
  // Hasura is treating views as nullable, but we know this isn't
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  const fabricColorCss = rgbToCssColor(themePublic.fabric_color!);
  const finishColorCss = themePublic.finish_type_id
    ? rgbToCssColor(themePublic.finish_color!)
    : undefined;
  const finishSize = stitchesToDimensions({
    ...chartDimensions,
    over: themePublic.over!,
    fabricCount: themePublic.fabric_count!,
    rectangleSpacings: [
      themePublic.fabric_margin!,
      themePublic.fabric_padding!,
    ],
  });
  /* eslint-enable @typescript-eslint/no-non-null-assertion */
  const [isLoaded, setIsLoaded] = useState(false);
  const widthRatio =
    finishSize.width < finishSize.height
      ? finishSize.width / finishSize.height
      : 1;

  const foregroundImageWrapperRef = useRef<HTMLDivElement>(null);

  // Check for already loaded image in cache
  // TODO remove once we can get the ref directly when this issue is resolved
  // https://git.io/J3qH5
  useEffect(() => {
    if (!foregroundImageWrapperRef.current || isLoaded) {
      return;
    }
    const image = foregroundImageWrapperRef.current.querySelector('img');
    if (image?.complete) {
      setIsLoaded(true);
    }
  }, [isLoaded]);

  return (
    <div
      className={className}
      tw="relative flex items-center w-full ring ring-gray-200"
    >
      {themePublic.background_file?.s3_url ? (
        <Image
          alt={`${name} background`}
          layout="fill"
          objectFit="cover"
          priority={priority}
          src={themePublic.background_file.s3_url}
        />
      ) : (
        <div tw="inset-0 absolute bg-gray-200" />
      )}
      <div
        css={dimBackground && tw`background-color[rgba(255,255,255,0.7)]!`}
        key="test"
        tw="absolute inset-0 background-color[rgba(255,255,255,0.1)]"
      />
      {!isLoaded && (
        <div
          css={{
            filter: !themePublic.foreground_file?.s3_url
              ? undefined
              : 'blur(8px)',
            // To avoid stretching the svg and creating a skewed frame look
            width: `${(widthRatio * 100).toFixed(2)}%`,
          }}
          tw="flex flex-col justify-center h-full padding[8%] mx-auto"
        >
          <svg
            css={[foregroundShadow, { color: fabricColorCss }]}
            tw="fill-current"
            viewBox={`0 0 ${finishSize.width} ${finishSize.height}`}
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width={finishSize.width}
              height={finishSize.height}
              stroke={finishColorCss}
              strokeWidth="10%"
            />
          </svg>
        </div>
      )}
      {themePublic.foreground_file?.s3_url && (
        <div css={foregroundImageWrapper} ref={foregroundImageWrapperRef}>
          <Image
            alt={name}
            layout="fill"
            objectFit="contain"
            onLoad={() => {
              if (!isLoaded) {
                setIsLoaded(true);
              }
            }}
            priority={priority}
            src={themePublic.foreground_file.s3_url}
          />
        </div>
      )}
    </div>
  );
};

export default ThemeImage;
